import React from 'react'

export type Props = {
  autoplay?: boolean
  src: string
  title?: string
} & React.ComponentPropsWithoutRef<'iframe'>

export default function YouTube({ autoplay, src, title, ...otherProps }: Props) {
  /**
   * Remove 3rd-party cookies and tracking.
   *
   * https://support.google.com/youtube/answer/171780#zippy=%2Cturn-on-privacy-enhanced-mode
   */

  const url = new URL(new URL(src).pathname, 'https://www.youtube-nocookie.com')

  if (autoplay) {
    url.searchParams.append('autoplay', '1')
  }

  /**
   * Do not show "More Videos".
   *
   * https://developers.google.com/youtube/player_parameters#rel
   */

  url.searchParams.append('rel', '0')
  url.searchParams.append('color', 'white')

  return <iframe allow='autoplay; fullscreen; picture-in-picture' src={`${url}`} title={title || src} {...otherProps} />
}

YouTube.scheme = {
  render: 'YouTube',
  attributes: {
    src: { type: String, required: true },
    title: { type: String }
  }
}
